import { useCallback, useEffect, useMemo, useState } from 'react'
import { shuffleArray } from '../helpers/util'
import useFetch from './useFetch'
import useSessionStorage from './useSessionStorage'
import { DEFAULT_OPTIONS } from '../const'
import { useTelegram } from './useTelegram'
const { REACT_APP_BE_URL } = process.env

export default function useOpenCase({ props }) {
	const { tg } = useTelegram()
	const params = new URLSearchParams(tg.initData)
	const hash = params.get('hash')
	const [dropIDs, setDropIds] = useState([])
	const [wonItems, setWonItems] = useState([])
	const [roulettes, setRoulettes] = useState([])
	const [value, setValue] = useSessionStorage(hash)
	const [fetch, setFetch] = useState(false)

	const options = useMemo(() => ({
		...DEFAULT_OPTIONS,
		body: JSON.stringify({ caseId: props.caseID, initData: tg.initData, openCount: props.openCount })
	}), [props.caseID, props.openCount, tg.initData])

	const preparedRouletteData = useCallback((data) => {
		setDropIds(data.dropIDs)
		const roulettesPrepared = data.wonItems.reduce((acum, cur) => {
			const newItems = [...data.rouletteItems]
			shuffleArray(newItems)
			newItems.splice(props.ix, 0, {
				name: cur.name,
				rarity: cur.rarity,
				image: cur.image
			})

			acum.push({
				items: newItems
			})

			return acum
		}, [])
		setRoulettes(roulettesPrepared)
	}, [props.ix])

	const preparedFarmData = useCallback((data) => {
		setDropIds(data.dropIDs)
		setWonItems(data.wonItems)
	}, [])

	const { data, isPending, error, executeFetch } = useFetch(`${REACT_APP_BE_URL}/open-case`, options, false)

	useEffect(() => {
		// fetch on the first render if session has not contain value
		if (!fetch && value === null) {
			executeFetch()
			setFetch(true)
		}
	}, [executeFetch, fetch, value])

	useEffect(() => {
		// on reload page take value from session if exist for regular case
		if (value !== null && !data && value.rouletteItems?.length) {
			preparedRouletteData(value)
		}
	}, [data, preparedRouletteData, value])

	useEffect(() => {
		// on reload page take value from session if exist for farm case
		if (value !== null && !data && value.wonItems?.length) {
			preparedFarmData(value)
		}
	}, [data, preparedFarmData, value])

	useEffect(() => {
		// on promise resolve regular case
		if (data && data?.rouletteItems?.length && data?.dropIDs?.length) {
			preparedRouletteData(data)
			setValue({ ...data, error: null, isPending: false })
		}
	}, [data, preparedRouletteData, setValue])

	useEffect(() => {
		// on promise resolve farm case
		if (data && data?.dropIDs?.length && data?.wonItems?.length && !data?.rouletteItems?.length) {
			preparedFarmData(data)
			setValue({ ...data, error: null, isPending: false })
		}
	}, [data, preparedFarmData, setValue])

	return { dropIDs, roulettes, error, isPending, wonItems }
}
