import { useTranslation } from 'react-i18next'
import './less/common.less'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { useTelegram } from './hooks/useTelegram'
import { useInitData } from './hooks/useInitData'
import { useNavigationApiData } from './hooks/useNavigationApiData'
import Layout from './pages/layout/layout'
import Roulettes from './pages/roulettes/roulettes'
import Error from './blocks/error/error'
import Home from './blocks/home/home'
import Refill from './pages/refill/refill'
import TestRoulettes from './pages/testRoulettes/testRoulettes'

function App() {
	const { tg } = useTelegram()
	const { ix, caseID, openCount, lng } = useInitData()
	const { isReloaded } = useNavigationApiData()
	const { i18n } = useTranslation()

	useEffect(() => {
		if (lng !== 'ru') {
			i18n.changeLanguage('en')
		}
	}, [lng, i18n])

	useEffect(() => {
		tg.ready()
		tg.expand()
	}, [tg])

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />

				{/* Using path="*"" means "match anything", so this route
						acts like a catch-all for URLs that we don't have explicit
						routes for. */}
				<Route path="*" element={<Error />} />
			</Route>
			<Route path="/roulettes" element={<Roulettes openCount={openCount} caseID={caseID} ix={ix} isReloaded={isReloaded}></Roulettes> } />
			<Route path="/refill" element={<Refill />} />
			<Route path="/test" element={<Layout />}>
				<Route path="/test/roulettes" element={<TestRoulettes />} />
			</Route>
		</Routes>
	)
}

export default App
