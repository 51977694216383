import { useCallback } from 'react'
import '../../less/common.less'
import { useSearchParams } from 'react-router-dom'
import Roulette from '../../blocks/roulette/roulette'
import Loader from '../../blocks/loader/loader'
import useOpenCaseTest from '../../hooks/useOpenCaseTest'
import { getClassFromOpenCount } from '../../helpers/util'

const TestRoulettes = () => {
	const [searchParams] = useSearchParams()
	const countRoulettes = Number(searchParams.get('count')) > 5 ? 5 : Number(searchParams.get('count'))
	const newClass = getClassFromOpenCount(countRoulettes)

	const { roulettes, isPending } = useOpenCaseTest({ countRoulettes })

	const handleRouletteIsFinished = useCallback(() => {
		console.log('finished')
	}, [])

	const classNameWrapper = `layout-roulettes ${newClass}`
	return (
		<div className={classNameWrapper}>
			{isPending && <Loader/>}
			{!!roulettes?.length && roulettes.map((r, ix) => <Roulette key={ix} items={r.items} ix={25} onRouletteIsFinished={handleRouletteIsFinished}/>)}
		</div>
	)
}

export default TestRoulettes
