export function useInitData() {
	const hash = window.location.hash.slice(1)
	const params = new URLSearchParams(hash)
	const items = JSON.parse(params.get('items'))
	const ix = parseInt(params.get('ix'), 10) || 25
	const dropID = (params.get('dropID')) || ''
	const caseID = (params.get('caseID')) || undefined
	const openCount = (params.get('openCount')) || 1
	const lng = (params.get('lng')) || 'ru'

	console.log({ caseID })

	return {
		items,
		ix,
		caseID,
		dropID,
		openCount,
		lng
	}
}
