export function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]]
	}
}

export function getClassFromOpenCount(openCount, roulettesCount) {
	switch (openCount) {
		case 1:
		case 2:
		case 10:
		case 25:
		case 50:
			return `layout-roulettes layout-roulettes_${roulettesCount}`
		case 3:
			return 'layout-roulettes layout-roulettes_three'
		case 4:
		case 5:
			return 'layout-roulettes layout-roulettes_more'
		default:
			return ''
	}
}
