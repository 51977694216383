import { useCallback, useEffect, useState } from 'react'
import { ERROR_FETCH } from '../const'

export default function useFetch(url, options, immediate = true) {
	const [data, setData] = useState(null)
	const [error, setError] = useState(null)
	const [isPending, setIsPending] = useState(false)

	const executeFetch = useCallback(async () => {
		setIsPending(true)
		setData(null)
		setError(null)
		await fetch(url, options)
			.then((response) => response.json())
			.then((response) => (response.error ? setError(response.error) : setData(response)))
			.catch((err) => setError(err?.message || ERROR_FETCH))
			.finally(() => setIsPending(false))
	}, [url, options])

	useEffect(() => {
		if (immediate) {
			executeFetch()
		}
	}, [executeFetch, immediate])

	return { data, error, isPending, executeFetch }
}
