export default function Item({ ix, i, props, wonItemStyle }) {
	return (
		<>
			<div key={ix} className={`roulette-item ${i.rarity} js-roulette-item ${(ix === props.ix && wonItemStyle) ? wonItemStyle : ''}`}>
				<img src={`https://community.cloudflare.steamstatic.com/economy/image/${i.image}/130fx90f/image.png?v=2`} alt=''></img>
				<div className="roulette-item__type">{i.name.split(' | ')[0]}</div>
				<div className="roulette-item__name">{i.name.split(' | ')[1]}</div>
			</div>
		</>
	)
}
