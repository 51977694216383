import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Input } from '../../ui/input'
import { useTelegram } from '../../hooks/useTelegram'
import { DEFAULT_OPTIONS, MAX_LENGTH_INPUT } from '../../const'
import useFetch from '../../hooks/useFetch'
import Error from '../error/error'
import Loader from '../loader/loader'
import useDebounce from '../../hooks/useDebounce'

const { REACT_APP_BE_URL } = process.env

export const Form = () => {
	const { tg } = useTelegram()
	const [promo, setPromo] = useState('')
	const methods = useForm()
	const { handleSubmit, formState: { errors } } = methods

	const debouncedValue = useDebounce(promo, 500)
	// useMemo needed for exclude infinite loop on event onChange input
	const options = useMemo(() => ({
		...DEFAULT_OPTIONS,
		body: JSON.stringify({ initData: tg.initData, promo: debouncedValue })
	}), [debouncedValue, tg.initData])

	const { data, error, isPending, executeFetch } = useFetch(`${REACT_APP_BE_URL}/refill`, options, false)
	const { data: dataPromo, error: errorPromo, executeFetch: executeFetchPromo } = useFetch(`${REACT_APP_BE_URL}/promocode`, options, false)

	const onSubmit = useCallback(() => {
		executeFetch()
	}, [executeFetch])

	const onChange = useCallback(() => {
		executeFetchPromo()
	}, [executeFetchPromo])

	useEffect(() => {
		if (debouncedValue) {
			onChange()
		}
	}, [debouncedValue, onChange])

	useEffect(() => {
		if (data?.redirectUrl) {
			window.open(data.redirectUrl, '_self')
		}
	}, [data])

	if (error) return <Error errMsg={error}/>
	if (errorPromo) return <Error errMsg={errorPromo}/>
	if (isPending) return <Loader/>

	return (
		<FormProvider {...methods}>
			{!data && <form
				className="promocode-form"
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				<div className="promocode-form__form">
					<div className="promocode-form__input">
						<Input
							name="promo"
							id="promo"
							placeholder="Промокод (при наличии)"
							value={promo}
							validation={{
								maxLength: {
									value: MAX_LENGTH_INPUT,
									message: `Максимум ${MAX_LENGTH_INPUT} символов`
								}
							}}
							onChange={(evt) => setPromo(evt.target.value)}
						/>
					</div>
					<div className="promocode-form__btn">
						<button	className="button button_main">Пополнить</button>
					</div>
					<div className="promocode-form__errors">
						{/* errors will return when field validation fails  */}
						{debouncedValue && dataPromo && !dataPromo?.success && <p>{dataPromo.msg}</p>}
						{errors?.promo && <p>{errors.promo.message}</p>}
					</div>
					<div className="promocode-form__success">
						{debouncedValue && dataPromo && dataPromo?.success && <p>{dataPromo.msg}</p>}
					</div>
				</div>
			</form>}
		</FormProvider>

	)
}
