import { useEffect, useRef, useState } from 'react'

export default function ItemFarm({ drop, delay, onRouletteIsFinished }) {
	const [open, setOpen] = useState(false)
	const item = useRef(null)
	const modifierRarity = `item-incase item-incase_small item-incase_${drop.rarity}`
	const modifierSelled = `farmcase-drop ${drop.selled ? '' : 'farmcase-drop-winner'}`

	useEffect(() => {
		let innerTimer
		const timer = setTimeout(() => {
			setOpen(true)
			onRouletteIsFinished()
			innerTimer = setTimeout(() => {
				item.current.scrollIntoView({ behavior: 'smooth' })
			}, delay)
		}, delay)
		return () => {
			clearTimeout(timer)
			clearTimeout(innerTimer)
		}
	}, [setOpen, delay, onRouletteIsFinished])

	return (
		<div ref={item} className={`${modifierSelled} ${open ? 'opened' : ''}`}>
			<div className="farmcase-drop-flipper">
				<div className="farmcase-drop-front"></div>
				<div className="farmcase-drop-back">
					<div className={modifierRarity}>
						<img className="item-incase__img" src={`https://community.cloudflare.steamstatic.com/economy/image/${drop.image}/130fx90f/image.png?v=2`} alt='' />
						<div className="item-incase__names">
							<div className="item-incase__first-name">{drop.displayFirstName}</div>
							<div className="item-incase__second-name">{drop.displaySecondName}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
