import { useEffect, useRef, useState } from 'react'
import { DELAY_DEBOUNCE } from '../const'

export default function useDebounce(value, delay = DELAY_DEBOUNCE) {
	const [debouncedValue, setDebouncedValue] = useState('')
	const timerRef = useRef()

	useEffect(() => {
		timerRef.current = setTimeout(() => setDebouncedValue(value), delay)

		return () => {
			clearTimeout(timerRef.current)
		}
	}, [value, delay])

	return debouncedValue
}
