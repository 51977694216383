import { useCallback, useEffect, useState, memo } from 'react'
import '../../less/common.less'
import { ANIMATION_SEC, DELAY_CENTER, DELAY_WIN } from '../../const'
import Item from '../item/item'

const Roulette = memo((props) => {
	const [animatePercent, setAnimatePercent] = useState(0)
	const [animateTimeS, setAnimateTimeS] = useState(0)
	const [wonItemStyle, setWonItemStyle] = useState('')
	const { onRouletteIsFinished, rouletteCenter = false } = props

	useEffect(() => {
		let tmo
		if (animateTimeS) {
			tmo = setTimeout(() => {
				setWonItemStyle('roulette-item_win')
				onRouletteIsFinished()
			}, DELAY_WIN)
		}
		return () => {
			if (tmo) {
				clearTimeout(tmo)
			}
		}
	}, [animateTimeS, onRouletteIsFinished])

	// useCallback is called when ref element is rendered
	const handler = useCallback(() => {
		const { translatePercent, centerPercent } = calculateTransformPercent({ wonIx: props.ix })
		setAnimatePercent(translatePercent)
		setAnimateTimeS(ANIMATION_SEC)
		if (rouletteCenter) {
			setTimeout(() => {
				setAnimatePercent(centerPercent)
			}, DELAY_CENTER)
		}
	}, [props.ix, rouletteCenter])

	return (
		<div className='layout-roulette'>
			<div className='roulette'>
				<div className='roulette__wrapper'>
					<div className='roulette__frame'>
						{!!props.items.length && <div
							ref={handler}
							className='roulette__items js-roulette-items'
							style={{
								transitionDuration: `${animateTimeS}s`,
								transitionTimingFunction: 'cubic-bezier(.03,1.03,.89,1)',
								transform: `translateX(-${animatePercent}%)`
							}}>
							{props.items.map((i, ix) => <Item key={`${i.name} - ${ix}`} i={i} ix={ix} wonItemStyle={wonItemStyle} props={props}/>)}
						</div>}
					</div>
				</div>
			</div>
		</div>
	)
})

export default Roulette

function calculateTransformPercent({ wonIx }) {
	const rouletteItems = document.getElementsByClassName('js-roulette-items')[0]
	const rouletteItemsWidth = rouletteItems.clientWidth

	// get full item size in roulette
	const rouletteItem = rouletteItems.getElementsByClassName('js-roulette-item')[0]

	const itemOuterSize = rouletteItem?.getBoundingClientRect().width
	const itemMargin = parseFloat(getComputedStyle(rouletteItem).getPropertyValue('margin-left'))
	const itemOuterSizeWithMargin = itemOuterSize + itemMargin * 2

	// randomise + calibrate to not cross the common line between item
	const randomPosition = getRandom(itemMargin + 5, itemOuterSize - 5)
	const translatePercent = (((wonIx * itemOuterSizeWithMargin) + randomPosition) / rouletteItemsWidth) * 100
	const centerPercent = (((wonIx * itemOuterSizeWithMargin) + itemOuterSizeWithMargin / 2) / rouletteItemsWidth) * 100
	return { translatePercent, centerPercent }
}

function getRandom(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min)
}
