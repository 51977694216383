import { useTranslation } from 'react-i18next'
import { useTelegram } from '../../hooks/useTelegram'

const Error = ({ errMsg }) => {
	const { tg } = useTelegram()
	const { t } = useTranslation()

	tg.MainButton.show()
	tg.MainButton.setText('Закрыть :(')
	tg.onEvent('mainButtonClicked', () => {
		tg.close()
	})
	return (
		<div className="error">
			<svg className="error__icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
				<g fill="none" fillRule="evenodd">
					<polygon fill="#BE2E2E" points="14 0 26.124 7 26.124 21 14 28 1.876 21 1.876 7"/>
					<line x1="10" x2="17.875" y1="17.875" y2="10" stroke="#FFF" strokeLinecap="square" strokeWidth="3"/>
					<line x1="10" x2="17.875" y1="17.875" y2="10" stroke="#FFF" strokeLinecap="square" strokeWidth="3" transform="rotate(90 13.938 13.938)"/>
				</g>
			</svg>
			<h2 className='error__title'>{t(errMsg)}</h2>
		</div>

	)
}

export default Error
