const testRouletteItems = [
	{
		name: 'AUG | Triqua',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot6-iFAR17PLddgJN_t24k4W0g-X9MrXWmm5u5cB1g_zMu4qmjQO3rhJoNm31J4PGIQNrMwyDqAK9yebr18Lq6JvLy3tl6ykh7S3D30vgaHf5G_Y'
	},
	{
		name: 'AWP | Snake Camo',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FBRw7ODGcDZH09GzkImemrmsY-PUxmgAv5Up2rnFrdmijlXgqUA_ZjzzIIKQcQA7Y1uE_Fbtlefum9bi68KPYhsE'
	},
	{
		name: 'CZ75-Auto | Nitro',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZfwPz3fi9D4tuzq4GIlPL6J6iDqWZU7Mxkh6eSo9Wm0FW3_hJrajinJtXDcg82MgnY-lS3leq8gJS56ZXOnCRh6yN2-z-DyFmfHqmu'
	},
	{
		name: 'AUG | Anodized Navy',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot6-iFAZu7P3JZyR97s63go-0m_7zO6-fzjsEv5Yp0uuQ8dui3wTt_RBsYG-lJdSXJg5sMFGDr1C7wO7sg5G06IOJlyVTQYQwXg'
	},
	{
		name: 'Desert Eagle | Oxide Blaze',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PDdTjlH_9mkgL-OlvD4NoTSmXlD58F0hNbM8Ij8nVn680E_ZDvwdo-Re1RtYA3W_gLrk-rngMC8upTJmHFmsiErs3jfnxe11wYMMLJe6xiBeg'
	},
	{
		name: 'Dual Berettas | Ventilators',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpos7asPwJf1OD3dShD4N6zhoWfg_bnDK3UmH9Y5MRlhfvSyoHwjF2hpl1qamD1Jo6UIVRoZAnU-AK5xO-51pG5v8zLwHU2u3Yr4nrfmRWx1B9JcKUx0onfL2Ru'
	},
	{
		name: 'Desert Eagle | Meteorite',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLFTj5Q9c-ilYyHnu3xN4TVl3la18l4jeHVu97x31Dj_kA9a2DwcdWWJAZvZV_Qqwe3x-fv1Me6uMmanCE3vSEm4ynD30vgrmdJqGM'
	},
	{
		name: 'Dual Berettas | Black Limba',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz5-OOqhNQhkZzvRBKFNU_sF8QTlHSIh18VxVcG5yLYDLVWq6ZyUZrItNtkYHsXTCPTVYVj740Jp1vVfeZOMpyK-iCrgP2cOWRDs-TgNhqbZ7VuDunxe'
	},
	{
		name: 'CZ75-Auto | Crimson Web',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz54LrTgMQhvazvVBKJNY_g_8AH5BGlj7p8yBIGwr-8Eelm6toLDZ-J9MNFETsDXUvTXYg_8u05qhvAML8bdvmqxikWkcSVq'
	},
	{
		name: 'Desert Eagle | Bronze Deco',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz5_MeKyPDJYcwn9A7JRUu8_yxv8CDU55MJcWdKy_q4LZw7os4LCMLcsMdAYTJbSUqWDZQr040hr06NaKZTdoHi83SzoPmwDCBf1ujVTJL13O6g'
	},
	{
		name: 'CZ75-Auto | Emerald',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZf0v33dDBH_t26kL-HnvD8J_WElT8Gu5Eg27iVotv00Azg80ZtMDimIo-ceg45YAuCrFbtyenv1sW6ot2Xntd6B4y4'
	},
	{
		name: 'Dual Berettas | Dualing Dragons',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpos7asPwJf0Ob3dShD4N6zhpKOg-P1DL_Dl2xe5tZOh-zF_Jn4xgHh_UY6YWv7cNPHcFBtYguD-Fbsx-rsh5e-upXIyXE3vydwtC3dykOpwUYb4qDnZ3s'
	},
	{
		name: 'CZ75-Auto | Polymer',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZf0v73cidUvuO7kr-GkvP9JrafxGkD7pUp3r-U8dX2iQHl-RI9Zmind4aWcwZsYlmB-gDrl-vr0568tYOJlyVmSeTYkA'
	},
	{
		name: 'AUG | Wings',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz56IeSKOC5YdAHDFahbTuYF9R3rNis77893GtLno-pfKwnt5YGSZ-QoYo0YGsPSUqCCZQGou05s1fBdKMeJ8nvt2SX3ejBdNGsYVYA'
	},
	{
		name: 'Desert Eagle | Corinthian',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PLZTjlH7du6kb-ImOX9Pa_Zn2pf18h0juDU-MKm2ley-kE6MGGnJIOXclA2ZQ7Vr1Lrlem8gpfvuMzOySBjsyd3s3vUgVXp1hBYWgPe'
	},
	{
		name: 'Desert Eagle | Urban Rubble',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposr-kLAtl7PvRTitD_tW1lY2EqOLmMbrfqWZU7Mxkh6eUo4j2i1G1_EdoYDrxJ4PHelU3ZVGBrgC9xufvjJG8vpjMzXpgvyZ0-z-DyDrrCVR0'
	},
	{
		name: 'AUG | Copperhead',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot6-iFA957PDHYS1H_tSzlYS0m_7zO6-fxz0H7sEk37zDpdyi3VKxqUVla277J4-ce1I3YV6D_lS8yLrt0ZC_vYOJlyXik3twsg'
	},
	{
		name: 'AK-47 | Black Laminate',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhoyszJemkV4N27q4KcqPrxN7LEmyUDsJIh27-YpYmmiVDm_UFuZ2vzJYPDJlRsYw2C8lC5w-fu0Je_6ZrB1zI97TOUU9Z0'
	},
	{
		name: 'AK-47 | Elite Build',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz56P7fiDzRyTQXJVfdhUfQp4A3-EBg_7cNqQdr48-9fLQrnsIXCNLkuYYxLGMbVD_SOMl_54kw8iaQMKsTY8yzq3y3oPXBKBUT7Bt81Vg'
	},
	{
		name: 'FAMAS | Hexane',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz59Ne60IwhvazvADbVbVPAiywXpDS4n5YkxDI_hoeoEfQzq54TCOuEvM4xFF5bWWPCFYQj-60w4hfVUfJaMpH7ow223bU8aEp6L'
	},
	{
		name: 'FAMAS | Doomkitty',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz59Ne60IwhvazvGDq9TV_wu4BHTBCI24dJuGoLhpOgHewnutdGUYuQpNt5MSpKFX6OGZg7_4x1p1aBZL5bdoSPn2iX3ejBdT4JNcno'
	},
	{
		name: 'AUG | Ricochet',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot6-iFAZt7PLddgJI-dG0mIW0m_7zO6-fkjMHsZUgi72T896m0VCwqEBlMD31IIPBcFc_ZlrY-1m2wLi6hpHouYOJlyUksb3lzA'
	},
	{
		name: 'CZ75-Auto | Imprint',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZf1OD3cicVueOihoWKk8j5Nr_Yg2YfvJB32ryWoYinigXkqBdpMGyhctWXdlU8YguC_1G9webng5fvtZSfmmwj5HeClNCSjw'
	},
	{
		name: 'CZ75-Auto | Twist',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz54LrTgMQhmfzvFGLJfSPAF-Q3oADI_psQ0V4_n8-xWfVrv5YHDOuV_MYsfHJWCDPTTZ1ir709uhqAOLpCAoinxnXO-uOr7WwI'
	},
	{
		name: 'CZ75-Auto | Hexane',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz54LrTgMQhvazvADbVbVPAiywXpDS4n5YllBYbipe0HLw_m5oCVMrMuOYseS8XYCP6PZAGu409uiKRdKJKI9Sjuw223bZyfXPm3'
	},
	{
		name: 'CZ75-Auto | Tuxedo',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpotaDyfgZfwPz3fi9B7eO6nYeDg7msZ-KBzj0E7cdw2b_CpdX2i1Dj_hJlMWilLYWRdgNoN1qF8we8k-a5m9bi6-1tbAt1'
	},
	{
		name: 'Dual Berettas | Cartel',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpos7asPwJf0uL3dShD4N6zhoWfg_bnDLjQhH9U5Pp8j-3I4IG7ilfj_RBrZDzyJoOdcgI9aVvWqAToxe3mg8Tv78zLynAw6CMl4XzYyQv330_FBz5Big'
	},
	{
		name: 'Dual Berettas | Panther',
		rarity: 'milspec',
		image: 'fWFc82js0fmoRAP-qOIPu5THSWqfSmTELLqcUywGkijVjZYMUrsm1j-9xgEObwgfEh_nvjlWhNzZCveCDfIBj98xqodQ2CZknz5-OOqhNQh0fTvSAK5KVPAoywXpDS4n5YliBtazruNQfgrssNfPN-IqYtkdSpTZU_OCYAir70luiaAPfZOIqHznw223bZvDH3kW'
	},
	{
		name: 'Dual Berettas | Anodized Navy',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpos7asPwJf0v33fzxU9eO6nYeDg7msZOKIz2hS7ZEki7mS89Tw0Ae3-Us4MWD7LIGRegc-MF2D81i-ku_vm9bi644LgQp9'
	},
	{
		name: 'AUG | Hot Rod',
		rarity: 'milspec',
		image: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot6-iFAZu7OHNdQJO5du-gM7SlvP2a-KFkDsD6cN33b6Z84rz0QXs8xJuZzymdYfDclU2M17W_Fm7366x0jzU3_8y'
	}
]

export default testRouletteItems
