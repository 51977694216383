import ItemFarm from '../itemFarm/itemfarm'
import { ANIMATION_FARM_SPEED_MS } from '../../const'

export default function Farm({ wonItems, onRouletteIsFinished }) {
	const modifierWrapper = `farmcase-drops farmcase-drops-${wonItems.length}`
	const modifierCard = `farmcase-drops__card farmcase-drops__card--${wonItems.length}`

	return (
		<div className="farmcase-section__drop-cards">
			<div className={modifierWrapper}>
				{wonItems.map((drop, i) => <div key={drop.id} className={modifierCard}><ItemFarm onRouletteIsFinished={onRouletteIsFinished} delay={ANIMATION_FARM_SPEED_MS * i} drop={drop}/></div>)}
			</div>
		</div>
	)
}
