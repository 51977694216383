export function useNavigationApiData() {
	if (window.performance) {
		console.info('window.performance works fine on this browser')

		console.log(window.performance
			.getEntriesByType('navigation')
			.map((nav) => nav.type))

		return {
			isReloaded: window.performance
				.getEntriesByType('navigation')
				.map((nav) => nav.type)
				.includes('reload')
		}
	}
}
