import { Outlet } from 'react-router'

function Layout() {
	return (
		<div className="main-page">
			<div className="main-page__header">
				<Outlet/>
			</div>
		</div>
	)
}

export default Layout
