export const I18N_DICTIONARY_EN = {
	QUEUE_CASE_OPEN_ERROR_1: "At the moment, our bots don't have the necessary items for the selected case. Try again later.",
	QUEUE_CASE_OPEN_ERROR_10: 'Case unavailable',
	QUEUE_CASE_OPEN_ERROR_11: 'There is no such case',
	QUEUE_CASE_OPEN_ERROR_2: 'At the moment, you can not open the case. Try again later. (Parameter validation error)',
	QUEUE_CASE_OPEN_ERROR_3: 'At the moment, you can not open the xN case. Try again later.',
	QUEUE_CASE_OPEN_ERROR_4: 'This case is temporarily unavailable',
	QUEUE_CASE_OPEN_ERROR_5: 'This case can not be bought for bonuses',
	QUEUE_CASE_OPEN_ERROR_5_1: 'Error opening case. Price is not specified in your currency.',
	QUEUE_CASE_OPEN_ERROR_6: 'This case can only be bought for bonuses',
	QUEUE_CASE_OPEN_ERROR_7: 'This case cannot be farmed',
	QUEUE_CASE_OPEN_ERROR_8: 'Limit has been reached',
	QUEUE_CASE_OPEN_ERROR_9: 'Case is already open',
	QUEUE_CASE_OPEN_ERROR_LOW_USER_PREM: 'Premium status of the case is higher than premium status of account',
	QUEUE_CASE_OPEN_ERROR_ONLY_PREM: 'Only premium users can open this case',
	QUEUE_DROP_NOT_FOUND: 'Specified item not found',
	QUEUE_DROP_TRADE_REQUESTED: 'Item is unavailable due to a requested trade',
	QUEUE_MONEY_ERROR: 'Недостаточно средств',
	QUEUE_REFILL_REQUIRED: 'To use this item, refill your account, please. For more information, hover on item',
	QUEUE_REPLACEMENT_ITEM_NOT_FOUND: 'Item for replacement not found',
	QUEUE_REQUEST_FAILED: 'Request failed',
	QUEUE_SELL_DROP_FAILED: 'Failed to sell all specified items',
	QUEUE_SELL_DROP_PROHIBITED: 'Sell of this item is not allowed',
	QUEUE_SIGNIN_ERROR: 'Log in',
	QUEUE_STEAM_LEVEL: 'Your Steam level is low. Possibly re-login is needed',
	QUEUE_TRADE_URL_ERROR: 'Specify a trade link in your profile',
	QUEUE_VALIDATION_FAILED: 'Validation error',
	balance_2: 'Refill your account balance',
	WRONG_DATA: 'Wrong data',
	BOT_DISABLED: 'Bot is disabled',
	EXPIRED_DATA: 'Data expired',
	UNAUTHORIZED: 'User is not authorized',
	REQUEST_TIMEOUT: 'Resource is not available',
	NOT_FOUND: 'Resource not found',
	CURRENCY_MISS: 'Currency miss',
	INVOICE_ERROR: 'Invoice error',
	TERMINAL_ERROR: 'Terminal error',
	CASE_ERROR: 'Case error',
	CASE_NOT_FOUND: 'Case not found',
	MONEY_NOT_ENOUGH: 'Money not enough',
	USER_NOT_FOUND: 'User not found',
	USER_ERROR: 'User error',
	DROP_NOT_FOUND: 'Drop not found'
}
