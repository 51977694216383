export const ANIMATION_SEC = process.env.NODE_ENV === 'development' ? 1 : 9
export const ANIMATION_FARM_SPEED_MS = process.env.NODE_ENV === 'development' ? 50 : 200
export const DELAY_WIN = (ANIMATION_SEC + 0.05) * 1000
export const DELAY_CENTER = (ANIMATION_SEC + 0.1) * 1000
export const DELAY_CASE_OVER = 1500
export const DELAY_DEBOUNCE = 600
export const MAX_LENGTH_INPUT = 50
export const DEFAULT_OPTIONS = {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json'
	}
}
export const ERROR_FETCH = 'Что-то пошло не так. Разбираемся'
