import { useFormContext } from 'react-hook-form'

export const Input = ({ label, type = 'text', id, placeholder, validation, name, onChange }) => {
	const { register } = useFormContext()

	return (
		<>
			{label && <label className="promocode-form__label" htmlFor={id}>{label}</label>}
			<input
				id={id}
				type={type}
				placeholder={placeholder}
				{...register(name, {
					...validation,
					onChange
				})}
			/>
		</>
	)
}
