import { Form } from '../../blocks/form/form'

function Refill() {
	return (
		<div>
			<Form/>
		</div>
	)
}

export default Refill
