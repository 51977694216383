import { useState, useCallback, useMemo } from 'react'
import '../../less/common.less'
import Roulette from '../../blocks/roulette/roulette'
import Error from '../../blocks/error/error'
import useOpenCase from '../../hooks/useOpenCase'
import useCaseOver from '../../hooks/useCaseOver'
import Loader from '../../blocks/loader/loader'
import { getClassFromOpenCount } from '../../helpers/util'
import { useTelegram } from '../../hooks/useTelegram'
import Farm from '../../blocks/farm/farm'

const Roulettes = (props) => {
	const { tg } = useTelegram()
	const [count, setCount] = useState(0)
	const { dropIDs, roulettes, error, isPending, wonItems } = useOpenCase({ props })
	const { error: errorCaseOver, fetch } = useCaseOver({ roulettes, wonItems, props, count, dropIDs })
	const modifier = useMemo(() => getClassFromOpenCount(+props.openCount, roulettes.length), [props.openCount, roulettes.length])
	const handleRouletteIsFinished = useCallback(() => {
		setCount((count) => count + 1)
	}, [])

	if (error || errorCaseOver) return <Error errMsg={error || errorCaseOver}/>
	if (fetch) {
		sessionStorage.clear()
		tg.close()
	}

	return (
		<div className={modifier}>
			{isPending && <Loader/>}
			{/* roulettes mode for regular cases and farm case x1 */}
			{roulettes?.length <= 10 && roulettes.map((r, ix) => <Roulette key={ix} items={r.items} ix={props.ix} onRouletteIsFinished={handleRouletteIsFinished}/>)}
			{/* farm mode for farm cases x10 */}
			{(wonItems?.length >= 10 && !roulettes?.length) && <Farm wonItems={wonItems} onRouletteIsFinished={handleRouletteIsFinished}/>}
		</div>
	)
}

export default Roulettes
