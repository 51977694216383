import { useEffect, useMemo, useState } from 'react'
import useFetch from './useFetch'
import { DEFAULT_OPTIONS, DELAY_CASE_OVER } from '../const'
import { useTelegram } from './useTelegram'
const { REACT_APP_BE_URL } = process.env

export default function useCaseOver({ roulettes, wonItems, props, count, dropIDs }) {
	const { tg } = useTelegram()
	const [fetch, setFetch] = useState(false)
	const options = useMemo(() => ({
		...DEFAULT_OPTIONS,
		body: JSON.stringify({ dropIDs, initData: tg.initData, caseID: props.caseID })
	}), [dropIDs, props.caseID, tg.initData])

	const { executeFetch, error } = useFetch(`${REACT_APP_BE_URL}/case-over`, options, false)

	useEffect(() => {
		let tmo
		if (count !== 0 && !error && (count === roulettes?.length || count === wonItems?.length)) {
			tmo = setTimeout(() => {
				executeFetch().then(() => {
					setFetch(true)
				})
			}, DELAY_CASE_OVER)
		}

		return () => clearTimeout(tmo)
	}, [count, error, executeFetch, roulettes?.length, wonItems?.length])
	return { error, fetch }
}
