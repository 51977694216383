import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { I18N_DICTIONARY_RU } from './ru'
import { I18N_DICTIONARY_EN } from './en'

const resources = {
	en: {
		translation: {
			...I18N_DICTIONARY_EN
		}
	},
	ru: {
		translation: {
			...I18N_DICTIONARY_RU
		}
	}
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'ru',
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	})

export default i18n
