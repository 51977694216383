import { useEffect, useMemo, useState } from 'react'
import roulette from '../pages/testRoulettes/test'
import { shuffleArray } from '../helpers/util'

export default function useOpenCaseTest({ countRoulettes }) {
	const [isPending, setIsPending] = useState(false)
	const [roulettes, setRoulettes] = useState([])

	const preparedRoulettes = useMemo(() => {
		const roulettes = []
		for (let i = 0; i < countRoulettes; i++) {
			const data = [...roulette]
			shuffleArray(data)
			roulettes.push({ items: data })
		}
		return roulettes
	}, [countRoulettes])

	useEffect(() => {
		setIsPending(true)

		const loading = setTimeout(() => {
			setIsPending(false)
			setRoulettes(preparedRoulettes)
		}, 300)
		return () => clearTimeout(loading)
	}, [preparedRoulettes])

	return { isPending, roulettes }
}
