import { useEffect, useState } from 'react'

export default function useSessionStorage(key, defaultValue = null) {
	const [value, setValue] = useState(() => {
		let currentValue

		try {
			currentValue = JSON.parse(
				sessionStorage.getItem(key) || String(defaultValue)
			)
		} catch (error) {
			currentValue = defaultValue
		}

		return currentValue
	})

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value))
	}, [key, value])

	return [value, setValue]
}
