import loader from '../../assets/img/logo_key.png'

export default function Loader() {
	return (
		<div className="preloader">
			<img src={loader}></img>
			<div className="preloader-whirlpool"></div>
		</div>
	)
}
