export const I18N_DICTIONARY_RU = {
	QUEUE_CASE_OPEN_ERROR_1: 'В данный момент на ботах нет вещей для выбранного кейса. Попробуйте позже.',
	QUEUE_CASE_OPEN_ERROR_10: 'Кейс недоступен',
	QUEUE_CASE_OPEN_ERROR_11: 'Такого кейса не существует',
	QUEUE_CASE_OPEN_ERROR_2: 'В данный момент нельзя открыть кейс. Попробуйте позже. (Ошибка валидации параметров)',
	QUEUE_CASE_OPEN_ERROR_3: 'В данный момент нельзя открыть кейс xN. Попробуйте позже.',
	QUEUE_CASE_OPEN_ERROR_4: 'Этот кейс временно недоступен',
	QUEUE_CASE_OPEN_ERROR_5: 'Этот кейс нельзя купить за бонусы',
	QUEUE_CASE_OPEN_ERROR_5_1: 'Ошибка при открытии кейса. Не указана цена в вашей валюте.',
	QUEUE_CASE_OPEN_ERROR_6: 'Этот кейс можно купить только за бонусы',
	QUEUE_CASE_OPEN_ERROR_7: 'Этот кейс нельзя фармить',
	QUEUE_CASE_OPEN_ERROR_8: 'Лимит истек',
	QUEUE_CASE_OPEN_ERROR_9: 'Кейс уже открыт',
	QUEUE_CASE_OPEN_ERROR_LOW_USER_PREM: 'Премиум статус кейса выше, чем премиум статус аккаунта',
	QUEUE_CASE_OPEN_ERROR_ONLY_PREM: 'Только пользователи с премиум аккаунтом могут открыть этот кейс',
	QUEUE_DROP_NOT_FOUND: 'Указанный предмет не найден',
	QUEUE_DROP_TRADE_REQUESTED: 'Этот предмет в данный момент недоступен, т.к. он отправляется',
	QUEUE_MONEY_ERROR: 'Недостаточно средств',
	QUEUE_REFILL_REQUIRED: 'Для использования дропа необходимо пополнить баланс. Подробнее можно узнать при наведении указателя мыши на дроп',
	QUEUE_REPLACEMENT_ITEM_NOT_FOUND: 'Предмет для замены не найден',
	QUEUE_REQUEST_FAILED: 'Ошибка при обработке запроса',
	QUEUE_SELL_DROP_FAILED: 'Не удалось продать весь дроп',
	QUEUE_SELL_DROP_PROHIBITED: 'Дроп запрещено продавать',
	QUEUE_SIGNIN_ERROR: 'Авторизуйтесь',
	QUEUE_STEAM_LEVEL: 'Недостаточный уровень Steam. Возможно нужен ре-логин',
	QUEUE_TRADE_URL_ERROR: 'Укажите Trade-ссылку в профиле',
	QUEUE_VALIDATION_FAILED: 'Ошибка валидации параметров',
	balance_2: 'Пополните баланс',
	WRONG_DATA: 'Ошибка данных',
	BOT_DISABLED: 'Бот выключен',
	EXPIRED_DATA: 'Данные устарели',
	UNAUTHORIZED: 'Пользователь не авторизован',
	REQUEST_TIMEOUT: 'Ресурс слишком долго отвечает',
	NOT_FOUND: 'Ресурс не найден',
	CURRENCY_MISS: 'Отсутствует валюта',
	INVOICE_ERROR: 'Ошибка при работе с инвойсом',
	TERMINAL_ERROR: 'Ошибка при работе с терминалом',
	CASE_ERROR: 'Ошибка с кейсом',
	CASE_NOT_FOUND: 'Кейс не найден',
	MONEY_NOT_ENOUGH: 'Не достаточно средств',
	USER_NOT_FOUND: 'Пользователь не найден',
	USER_ERROR: 'Ошибка с пользователем',
	DROP_NOT_FOUND: 'Дроп не найден'
}
